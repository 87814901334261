.logoMobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.searchIcon {
  position: absolute;
  top: 21px;
  left: 23px;
}

.arrow_left {
  transform: rotate(180deg);
}

.arrow_down {
  transform: rotate(90deg);
  transition: transform $transition_primary linear;

  &.open {
    transform: rotate(270deg);
  }
}

.arrow_up {
  transform: rotate(-90deg);
}

.calendar__icon_left {
  position: absolute;
  top: 9px;
  left: 10px;
  pointer-events: none;
}

.calendar__icon_right {
  position: absolute;
  top: 9px;
  right: 10px;
  pointer-events: none;
}
