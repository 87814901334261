.accordion-item {
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
  margin-bottom: 18px;
  .accordion-title {
    color: #38417c;
    letter-spacing: 1px;
    margin: 0;
    padding: 39px 42px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    .sign {
      cursor: pointer;
    }
  }
  .accordion-body {
    overflow: hidden;
    transition: all 0.5s;
    .accordion-content {
      padding: 0 22px;
      padding-bottom: 26px;
      .appointments-list {
        background: #f8f8fd;
        box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
        border-radius: 8px;
        padding: 19px;
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.25px;
        margin-bottom: 8px;
        display: flex;
        color: #38417c;
        justify-content: space-between;
      }
      .name {
        font-weight: normal;
      }
      .accordion-btn {
        font-family: Euclid Flex;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding: 3.5px 9.5px;
        color: #fff !important;
        text-align: center;
        text-transform: uppercase;
        background: #38417c;
        opacity: 0.2;
        border-radius: 36px;
      }
    }
  }
}
