.markerBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.geolocateControl {
  position: absolute;
  top: 10px;
  right: 10px;
}
