html {
  -webkit-font-smoothing: antialiased;
}
body {
  background: #f9faff;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  line-height: normal;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
}
html,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
hr,
iframe {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
}
a,
input,
select,
textarea {
  outline: none;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.4;
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
  color: #000;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
}
a:hover {
  text-decoration: none;
}
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
  text-decoration: none;
  color: #000;
}
img {
  max-width: 100%;
  vertical-align: middle;
  border: none;
  outline: none;
  border-radius: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  margin: 0;
  padding: 0;
}
input {
  resize: none;
  border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select,
input[type="submit"] {
  cursor: pointer;
}
p {
  margin: 0px;
  padding: 0px;
  line-height: 1.4;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

html {
  /* scroll-behavior: smooth !important; */
}

button:focus {
  outline: none;
}

p {
  margin-bottom: 0;
}
ul {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

@font-face {
  font-family: "EuclidFlexMedium";
  src: url("../fonts/EuclidFlexMedium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlexMedium.otf") format("opentype"),
    url("../fonts/EuclidFlexMedium.woff") format("woff"),
    url("../fonts/EuclidFlexMedium.ttf") format("truetype"),
    url("../fonts/EuclidFlexMedium.svg#EuclidFlexMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova-Regular.woff") format("woff"),
    url("../fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("../fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
nav.navbar.navbar-expand-lg {
  padding: 0;
}
.menu_wrapper {
  padding: 22px 0;
  border-bottom: 1px solid rgba(56, 65, 124, 0.2);
  background: #fff;
}
img.desktop-logo {
  display: block;
}
img.mobile-logo {
  display: none;
}
h1 {
  font-family: "EuclidFlexMedium";
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -1.5px;
  color: #343642;
  margin-bottom: 22px;
  font-weight: normal;
}
.registration-page h1 {
  margin-bottom: 25px;
}
.register-form {
  margin-top: 30px;
}
h2 {
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.6px;
  color: rgba(56, 65, 124, 0.7);
  font-weight: normal;
  margin-bottom: 60px;
}
h3 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: rgba(56, 65, 124, 0.7);
  font-weight: 500;
  margin-bottom: 10px;
}
h4 {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.325px;
  color: rgba(56, 65, 124, 0.3);
}
.img-wrppaer {
  text-align: right;
  position: fixed;
  right: 0;
  bottom: 0;
}
.main-bg-sectoin {
  padding: 120px 0 0 0;
}
p {
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.425px;
  font-weight: normal;
  color: rgba(56, 65, 124, 0.7);
  margin: 15px 0 0 0;
  font-weight: 500;
}
a {
  color: #38417c;
}
.form-group.value-group {
  position: relative;
  max-width: 285px;
  width: 100%;
}
.form-group .form-control {
  background: #ffffff;
  border: 1px solid #38417c;
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  border-radius: 8px;
  height: 55px;
  position: relative;
  padding-left: 20px;
  font-weight: 500;
}
.sign-circle {
  width: 24px;
  height: 24px;
  background: #747aa3;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.menu_wrapper .drop-down .selected a {
  display: block;
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.menu_wrapper .drop-down .selected a span {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  vertical-align: middle;
  font-weight: 600;
  color: rgba(56, 65, 124, 0.84);
  background: url(../images/arrow.svg) no-repeat right;
  padding-right: 28px;
}
.menu_wrapper .drop-down .selected a i {
  color: #9fa3b1;
  padding-left: 10px;
  font-size: 24px;
  vertical-align: middle;
}
.menu_wrapper .drop-down .option {
  position: relative;
}

.menu_wrapper .drop-down .options ul {
  display: none;
  list-style: none;
  padding: 0px 0px;
  position: absolute;
  left: 0px;
  top: 20px;
  margin-top: 15px;
  width: 100%;
  max-width: 205px;
  background: #ffffff;
  border: 1px solid rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
  z-index: 9;
}

.menu_wrapper .drop-down .selected span.value,
.menu_wrapper .drop-down .options span.value {
  display: none;
}
.menu_wrapper .drop-down .options ul li {
  padding: 0 10px;
}
.menu_wrapper .drop-down .options ul li a {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* padding: 14px 5px; */
  color: rgba(56, 65, 124, 0.8);
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid rgba(56, 65, 124, 0.17);
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
}
.menu_wrapper .drop-down .options ul li:last-child a {
  border-bottom: none;
}
/*.drop-down .options ul li a:hover{
  background:#3179ac;
  color:#fff;
  transition:0.2s ease;
}*/

form input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
form input::-moz-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
form input::-ms-input-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
form input::-moz-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
p.desktop-p {
  display: none;
}
.primary-btn {
  font-size: 24px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #ffffff;
  background-color: #38417c;
  max-width: 335px;
  width: 100%;
  display: inline-block;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: 0.5s;
  border: 1px solid transparent;
}
.primary-btn:hover,
.primary-btn:focus {
  background-color: transparent;
  color: #38417c;
  border: 1px solid #38417c;
}

/*desktop-page-css*/
#site-topnav {
  padding: 0;
}
#site-topnav .navbar-toggle {
  background-color: transparent;
}
#site-topnav .navbar-toggle .menu {
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(56, 65, 124, 0.84);
  cursor: pointer;
}
#site-topnav .navbar-toggle .menu img {
  margin-left: 10px;
}
.navbar-collapse {
  position: absolute;
  width: 100%;
  top: 100%;
  right: 0px;
  max-width: 300px;
  z-index: 11;
}
.user-detail-popup {
  background-color: #fff;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
  border-radius: 4px;
  z-index: 1;
  border: 1px solid rgba(56, 65, 124, 0.17);
}
.user-detail-popup li a {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(56, 65, 124, 0.8) !important;
  padding: 20px;
  display: inline-block;
  width: 100%;
}
.user-detail-popup li {
  border-top: 1px solid rgba(56, 65, 124, 0.17);
  margin: 0 20px;
}
.user-detail-popup li:first-child {
  border-top: 1px solid transparent;
  display: flex;
}
.user-detail-popup li:first-child h2 {
  margin-bottom: 0;
  color: #38417c;
  padding-left: 20px;
  margin-top: 20px;
}
.user-detail-popup li:first-child a {
  padding: 20px 0 20px 0;
  width: auto;
}
li.nav-item a {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.content-row [class*="col-"] {
  margin-bottom: 34px;
}
.box-wrapper h2 {
  color: #38417c;
  margin-bottom: 15px;
  margin-top: 20px;
}
.box-wrapper small {
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.7);
  font-weight: normal;
}
.box-wrapper {
  background: #ffffff;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 25px;
  transition: 0.5s;
  box-shadow: 0px 2px 14px rgba(56, 65, 124, 0.17);
  height: 100%;
}
.box-wrapper.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.box-wrapper .circle {
  border: 2px solid rgba(56, 65, 124, 0.7);
  width: 23px;
  height: 23px;
  display: inline-block;
  border-radius: 100%;
  font-size: 21px;
  font-weight: 700;
  color: #38417c;
  line-height: 19px;
  text-align: center;
}
.box-wrapper:hover {
  border: 1px solid #38417c;
}
.goodmorning-text {
  max-width: 250px;
  width: 100%;
}
.content-wrapper {
  display: flex;
  align-items: center;
  padding-left: 166px;
  margin-right: 10px;
}
.inner-desktop-content {
  display: flex;
  position: relative;
  z-index: 1;
}
.circle-img-wrppaer {
  position: absolute;
  right: 0;
  top: 162px;
}
.first-row {
  height: 100%;
}
.box-img img {
  border-radius: 50px;
}

/*.box-wrapper:nth-child(4) h2 {
  color: #747aa3;
}*/

/* Registration Desktop css*/
.register-form .fild-row {
  margin-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
}
.fild-row .drop-down {
  border: 1px solid #d2d4de;
  border-radius: 8px;
  background: #ffffff;
}

.fild-row .selected a {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 19px;
  position: relative;
  background: url(../images/select-arrow.svg) no-repeat 96%;
  width: 100%;
  color: rgba(56, 65, 124, 0.5);
}
.fild-row .open-list .selected a {
  background: url(../images/select-arrow-open.svg) no-repeat 96%;
  color: #38417c;
}
.fild-row .form-control:focus::placeholder {
  color: #38417c;
}
.fild-row .drop-down.open-list {
  border: 1px solid #38417c;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
  position: relative;
}
.fild-row .selected a span {
  width: 100%;
  display: block;
}
.fild-row .selected a i {
  position: absolute;
  right: 20px;
  top: 20px;
}
/* .fild-row .options{
    position: absolute;
    top: 100%;
} */
.fild-row .options ul {
  display: none;
}
.fild-row .options ul li a {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 19px;
  border-top: 1px solid #d2d4de;
}
.register-form .fild-row label {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.5);
  padding: 5px 20px 0;
  margin-bottom: -22px;
  display: block;
}
.register-form .fild-row:focus label {
  color: #38417c;
}
.register-form .fild-row .half {
  width: 47.5%;
  float: left;
  margin-left: 5%;
}
.register-form .fild-row .half:first-child {
  margin-left: 0;
}
.register-form .fild-row .half .form-control {
  text-align: center;
}
.register-form .fild-row .form-control {
  background: #ffffff;
  border: 1px solid #d2d4de;
  border-radius: 8px;
  height: 56px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #38417c;
  padding: 20px;
}
.register-form .fild-row .form-control:focus {
  color: #38417c;
  border: 1px solid #38417c;
}
.register-form .fild-row.label .form-control {
  padding: 20px 20px 10px 20px;
}

.fild-row .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #38417c;
}
.fild-row .form-control::placeholder {
  color: rgba(56, 65, 124, 0.5);
}

.fild-row .form-control:focus::placeholder {
  color: rgba(56, 65, 124, 0.5);
}
.fild-row .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #38417c;
}
.fild-row .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #38417c;
}
.fild-row .primary-btn {
  max-width: 100%;
}
/*add-office-desktop*/

.main-bg-sectoin.add-office-page h1 {
  width: 100%;
}
.main-bg-sectoin.add-office-page .form-group .form-control {
  height: 64px;
  padding: 21px 51px 21px 21px;
}
.main-bg-sectoin.add-office-page .sign-circle {
  top: 20px;
}
.main-bg-sectoin.add-office-page h2 {
  margin-bottom: 50px;
}
a.secondary-btn {
  max-width: 183px;
  width: 100%;
  height: 64px;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.6px;
  background-color: rgba(56, 65, 124, 0.5);
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border: 1px solid rgba(56, 65, 124, 0.2);
}
a.secondary-btn:hover {
  background-color: transparent;
  color: #747aa3;
  border: 1px solid #747aa3;
}
.add-office-form {
  display: flex;
  margin: 50px 0 0 0;
}
.main-bg-sectoin.add-office-page a.secondary-btn {
  margin-left: 20px;
}
span.arrow-circle {
  height: 20px;
  width: 20px;
  background-color: #38417c;
  border-radius: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-wrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 50px;
}
.cancel-wrapper .arrow_icon {
  color: #38417c;
  font-size: 20px;
  display: inline-block;
  vertical-align: bottom;
}
.cancel-wrapper a {
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  color: #38417c;
}

.cancel-wrapper a:hover,
.cancel-wrapper a:hover .arrow_icon {
  color: #747aa3;
}
.man_img {
  display: none;
}

/*** added css ***/
.form-control:focus {
  box-shadow: none;
}
.options ul li {
  text-align: left;
}
.drop-down-wrpper {
  position: relative;
  width: 104px;
  text-align: right;
  margin-top: 10px;
}
.register-form .drop-down:focus {
  border: 1px solid #38417c;
}
.register-form button[type="submit"] {
  cursor: pointer;
  margin-top: 7px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
}
.main-bg-sectoin .col-lg-4 {
  padding-right: 7px;
}
.mobile-leaves {
  display: none;
}
.navbar-brand {
  max-width: 145px;
}
.navbar-brand img {
  width: 145px;
}
.goodmorning-text h2 {
  color: rgba(56, 65, 124, 0.7);
  max-width: 200px;
}
.content-row .col-6 {
  padding: 0 10px;
}
.box-img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: relative;
}
.box-img img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.add-new-office h2 {
  color: rgba(56, 65, 124, 0.7);
}
.dots {
  height: 14px;
  width: 14px;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 4px;
  background: #5ad4bb;
  border-radius: 100%;
}
.add-new-office-main p {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.7);
}
.add-office-form .form-control::placeholder {
  color: rgba(56, 65, 124, 0.5);
}
.add-office-form .form-control::placeholder,
.add-office-form .form-control {
  color: rgba(56, 65, 124, 0.5);
  font-size: 18px;
  font-weight: 500;
}
.office-home-left {
  max-width: 250px;
}
/*** office home page css start ***/
.office-home-left .box-img {
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
}
.office-home-left .dots {
  height: 23px;
  width: 23px;
}
.office-info h2 {
  color: #38417c;
}
.office-info h2 span {
  display: block;
}
.office-home-left h2 {
  margin-bottom: 40px;
}
.office-icons {
  height: 80px;
  width: 80px;
  border-radius: 14px;
  float: left;
  text-align: center;
  line-height: 80px;
  position: relative;
}
.yellow-sqr {
  background: #ffc700;
}
.green-sqr {
  background: #5ad4bb;
}
.pink-sqr {
  background: #d45a8d;
}
.office-home-info {
  display: table;
  padding-left: 22px;
}
.office-home-info h2 {
  color: #38417c;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.9px;
  margin: 0 0 -10px 0;
}
.office-home-info p {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  letter-spacing: -0.375px;
  max-width: 126px;
  margin-top: 12px;
}
.office-home-info-right {
  max-width: 260px;
  margin: 0 auto;
  opacity: 0.5;
  transition: 0.4s;
}
.office-home-right .box-wrapper:hover .office-home-info-right,
.office-home-right .box-wrapper:focus .office-home-info-right,
.office-home-right .box-wrapper .office-home-info-right.active {
  opacity: 1;
}
.office-home-right .box-wrapper {
  padding: 48px 50px 58px 50px;
  border: 0;
}
.office-home-right .box-wrapper:hover,
.office-home-right .box-wrapper:focus {
  border: 0;
}
/* .counter {
  height: 24px;
  width: 24px;
  background: #38417c;
  font-size: 13px;
  letter-spacing: -0.325px;
  border-radius: 100%;
  color: #fff;
  display: block;
  line-height: 25px;
  position: absolute;
  left: 6px;
  top: 16px;
} */
.office-home-right .content-row [class*="col-"] {
  margin-bottom: 20px;
}
.content-wrapper.office-home-right {
  display: block;
  padding-top: 70px;
}
.main-bg-sectoin.less-space {
  padding-top: 56px;
}
/*** triage appointment page css start ***/
.appointment-box {
  background: #fcfcff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  min-height: 386px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.schedule-appointment {
  background: #ffffff;
  border: 1px solid rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  padding: 13px;
}
.triage-app-left {
  max-width: 488px;
}
.content-wrapper.triage-app-right {
  display: block;
  width: 100%;
  padding: 77px 0 0 57px;
  margin-right: 0;
}
.walkin-appointment {
  margin: 20px 0 0 0;
}
.walkin-appointment .primary-btn {
  max-width: 100%;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  line-height: 63px;
}
.appointment-box h3 {
  color: rgba(56, 65, 124, 0.4);
  font-weight: bold;
  margin-bottom: 0;
}
.appointment-box h4 {
  color: rgba(56, 65, 124, 0.4);
  font-weight: bold;
  margin-bottom: 0;
}
.next-step {
  margin-top: 20px;
  display: -mozflex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: right;
  -webkit-justify-content: right;
  -ms-justify-content: right;
  justify-content: right;
}
.next-step .primary-btn {
  display: inline-block;
  vertical-align: middle;
  line-height: 64px;
  text-transform: capitalize;
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  max-width: 285px;
}
.next-step .primary-btn.small {
  max-width: 184px;
}
/*** triage symptoms page css start ***/
/*** custom checkbox button css start ***/
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  position: relative;
  cursor: pointer;
  line-height: 21px;
  display: inline-block;
  color: #38417c;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  letter-spacing: -0.45px;
  font-weight: bold;
}
.checkbox-custom + .checkbox-custom-label::before {
  content: "";
  border: 2px solid rgba(56, 65, 124, 0.9);
  width: 24px;
  height: 24px;
  margin-right: 15px;
  text-align: center;
  border-radius: 100%;
  float: left;
}
.checkbox-custom:checked + .checkbox-custom-label::after {
  content: "";
  text-align: center;
  position: absolute;
  top: 0;
  left: 30px;
  background: rgba(56, 65, 124, 0.9) url(../images/check.svg) no-repeat center
    center;
  width: 24px;
  height: 25px;
  border-radius: 100%;
}
.checkbox-custom-label span {
  padding: 2px 0 0 0;
  display: table;
}
/*** custom checkbox button css end ***/
.symptoms-listing {
  float: left;
  width: 100%;
  text-align: left;
  padding-right: 40px;
}
.symptoms-listing li {
  margin-top: 13px;
  width: 50%;
  float: left;
}
.symptoms-listing li:first-child {
  margin-top: 0;
}
/*** traige details page css start ***/
.triage-details .form-control {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
  min-height: 170px;
  padding: 20px;
  resize: none;
  border: 1px solid rgba(56, 65, 124, 0.2);
  border-radius: 8px;
}
.word-limit {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.5);
  text-align: right;
  letter-spacing: -0.375px;
  padding: 10px 0 0;
}
.triage-details-left {
  max-width: 422px;
}
.triage-details-main .triage-app-right {
  padding-left: 208px;
}
.account-sign-register {
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.425px;
  color: rgba(56, 65, 124, 0.7);
}
.account-sign-register a {
  color: #38417c;
}
.account-sign-register a:hover,
.account-sign-register a:focus {
  color: rgba(56, 65, 124, 0.7);
}
/*** list-of-desktop page css start ***/
.content-wrapper.list-desktop-right {
  display: block;
  width: 100%;
  padding: 77px 0 0 57px;
  margin-right: 0;
}
.accordion {
  padding: 35px 0;
}
.accordion .card {
  padding: 25px;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  background: #ffffff;
  border-radius: 8px !important;
  margin-bottom: 20px;
  border: 1px solid transparent !important;
}
.accordion .card-header {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.accordion .card-header .card-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
/* .accordion .card-header .card-wrapper .rotate-icon {
	
	color: rgba(55, 64, 123, 0.17);
	font-size: 22px;
	background: url(../images/select-arrow.svg) no-repeat;
} */
.accordion .card-header > a {
  background: url(../images/select-arrow-blue.svg) no-repeat right;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.accordion .card-header a.collapsed {
  background: url(../images/select-arrow.svg) no-repeat right;
}
.accordion h2 {
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 23px;
  font-weight: 700;
  color: #38417c;
  font-weight: semibold;
}
.accordion small {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.7);
  margin-bottom: 10px;
  display: block;
}

.accordion .card-header .box-profile {
  margin-left: 30px;
}
.accordion .card-body {
  padding: 40px 0 0;
}
.accordion .card-body ul {
  display: flex;
  margin-bottom: 30px;
}
.accordion .card-body ul li {
  padding: 0 30px 0 0;
}
.accordion .card-body button {
  max-width: 100%;
  cursor: pointer;
}
.modal-open .modal {
  padding-left: 17px;
}
.modal-dialog {
  max-width: 322px;
}
.modal-content .modal-header {
  padding: 20px 20px 5px;
  border: 0;
}
.modal-content .modal-footer {
  border: 0;
  padding: 0 20px 20px;
}
.modal-content .modal-header .modal-title {
  color: #38417c;
  font-size: 24px;
  line-height: 23px;
  font-weight: 700;
}
.modal-content .modal-body {
  color: #38417c;
  opacity: 0.7;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  padding: 0 20px 10px;
}
.modal-content .modal-footer button {
  margin-bottom: 0;
  height: 38px;
  font-size: 13px;
  line-height: 17px;
}
.list-dotors-right .primary-btn {
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
}
.accordion .card.open-accordion {
  border: 1px solid #38417c !important;
}
.modal-open {
  background: rgba(33, 57, 73, 0.85);
}
.modal-footer .btn {
  width: 50%;
  border-radius: 8px;
}
.modal-footer .secondary-btn {
  background: transparent;
  border: 1px solid #38417c;
}
.modal-footer .secondary-btn:hover,
.modal-footer .secondary-btn:focus {
  background: #38417c;
  color: #fff;
}
/*** waiting room page css start ***/
.waiting-room-left .dots {
  height: 12px;
  width: 12px;
}
.waiting-room-left .box-img {
  width: 56px;
  height: 56px;
}
.waiting-room-left h1 {
  font-size: 36px;
  line-height: 36px;
  margin-top: 20px;
}
.waiting-room-left {
  max-width: 281px;
}
.waiting-room-right-main {
  padding: 65px 0 0 0;
}
.dots.orange {
  background: #ffc700;
}
/*** waiting room ready page css start ***/
.waiting-room-main {
  padding-bottom: 90px;
}
.waiting-room-left .primary-btn {
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  height: 56px;
  max-width: 280px;
  cursor: pointer;
}
.waiting-room-left h2 {
  margin-bottom: 30px;
}
.waiting-room-top-sec {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.waiting-main-img {
  float: left;
  width: 50%;
  padding: 0 10px 0 0;
}
.waiting-content {
  width: 100%;
  padding-left: 10px;
  float: left;
  height: 406px;
}
.waiting-content > img,
.video-setting > img,
.waiting-main-img > img {
  width: 100%;
}
.no-padding {
  padding: 0;
}
.incall-middle,
.incall-last {
  float: left;
  width: 50%;
}
.incall-mobile > img {
  width: 100%;
}
.man-img {
  max-width: 626px;
  width: 100%;
}
/* .triage-app-right .next-step  {
    display: block !important;
} */
.options ul {
  position: absolute;
  width: calc(100% + 2px);
  background: #fff;
  left: -1px;
  top: 51px;
  z-index: 9;
  border: 1px solid #38417c;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
}
.fild-row.gender .half {
  position: relative;
}
.gender label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  right: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 18px !important;
  line-height: 50px !important;
  cursor: pointer;
}
.gender input[type="radio"]:checked + label {
  color: #38417c;
}
/*** waititng room added css ***/
.waiting-main-img {
  float: left;
  width: 50%;
  padding: 0 10px 0 0;
}
.waiting-content {
  width: 50%;
  padding-left: 10px;
  background: #fcfcff;
  border-radius: 14px;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  float: left;
  padding: 15px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
}
.waiting-room-top-sec {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.left .chat-box {
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  background: #fff;
}

.chat-text {
  padding-left: 25px;
}
.chat-text h3 {
  color: #38417c;
  text-align: right;
  font-weight: Semibold;
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}
.chat-user {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: -4px;
}
.chat-info {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.chat-box {
  padding: 15px;
  border-radius: 8px;
  background: rgba(56, 65, 124, 0.0967);
}
.chat-box p {
  color: #38417c;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin: 0 0 13px 0;
}
.chat-time {
  font-size: 13px;
  letter-spacing: -0.32px;
  color: rgba(56, 65, 124, 0.7);
}
.left .chat-info {
  text-align: left;
}
.left .chat-user {
  float: left;
}
.chat-text {
  margin-top: 18px;
}
.chat-text:first-child {
  margin-top: 0;
}

.chat-text:last-of-type {
  padding-bottom: 0px;
}
.type-msg .common-input {
  border: 1px solid rgba(56, 65, 124, 0.17);
  font-size: 16px;
  border-radius: 8px;
  font-weight: 500;
  height: 59px;
  width: 100%;
  padding: 20px;
  color: rgba(56, 65, 124, 0.5);
}
.type-msg .common-input:focus {
  border: 1px solid #38417c;
}
.type-msg {
  position: absolute;
  bottom: 40px;
  width: calc(100% - 80px);
  left: 40px;
}
.chat-text-main {
  padding-bottom: 60px;
  height: 72%;
  overflow: scroll;
}
.video-setting {
  background: #fcfcff;
  box-shadow: 0px 2px 14px rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  padding: 40px;
  margin: 20px 0 0 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-align: left;
}
.video-setting h2 {
  font-size: 36px;
  line-height: 38px;
  color: #38417c;
  letter-spacing: -0.9px;
  margin-bottom: 20px;
}
.source h4 {
  color: rgba(56, 65, 124, 0.7);
  margin-bottom: 7px;
}
.source select {
  background: rgba(200, 203, 224, 0.3) url(../images/arw.svg) no-repeat 95%;
  border-radius: 5px;
  border: 0;
  font-size: 18px;
  line-height: 16px;
  color: #38417c;
  font-weight: bold;
  padding: 12px;
  width: 100%;
  font-family: "Proxima Nova";
}
.source select option {
  font-family: "Proxima Nova";
}
.source-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.source-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.source.left-s {
  padding-right: 10px;
}
.source.right-s {
  padding-left: 10px;
}
.source {
  float: left;
  width: 50%;
}
.vol-range .vol {
  float: left;
  color: #737aa3;
  font-size: 9px;
  line-height: 17px;
  margin: 5px 0 0 0;
}
.vol-range .vol:last-child {
  float: right;
}
.vol-info h4 {
  float: left;
  margin: 6px 0 0 0;
}
.speaker-vol span {
  background: url(../images/speaker.svg) no-repeat;
  padding-left: 10px;
}
.speaker-vol {
  background: #38417c;
  border-radius: 5px;
  float: right;
  color: #fff;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  width: 81px;
  height: 22px;
  line-height: 23px;
}
.speaker-vol:hover,
.speaker-vol:focus {
  background: rgba(56, 65, 124, 0.7);
  color: #fff;
}
.player {
  display: inline-block;
  vertical-align: middle;
  width: 232px;
  margin: 30px 0 0 0;
}
.vol-info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 0px 0 20px 0;
}
.micro-vol {
  background: url(../images/micro.png) no-repeat;
  width: 47px;
  height: 33px;
  display: block;
  float: right;
  background-size: 47px;
}
.microphone-l span {
  left: 148px;
}
.close-chat {
  background: rgba(56, 65, 124, 0.14) url(../images/close.svg) no-repeat center
    center;
  border-radius: 100%;
  width: 59px;
  height: 59px;
  float: right;
}
.send-chat {
  background: rgba(56, 65, 124, 0.14) url(../images/send-icon.svg) no-repeat
    center center;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  float: right;
}
.type-msg.with-close form {
  width: calc(100% - 80px);
  float: left;
}
.incall-middle {
  padding-right: 15px;
}
.notifc {
  background: #ff7259;
  width: 17px;
  height: 17px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  line-height: 18px;
  position: absolute;
  border-radius: 100%;
  right: 7px;
  top: 10px;
  font-style: normal;
}
.tabbing-sec .nav-item {
  position: relative;
  display: inline-block;
}
.tabbing-sec .nav-item .o-tooltip {
  position: absolute;
  z-index: 999;
  background: transparent;
  width: 100%;
  left: 0;
  border-radius: 100%;
  top: 0;
  height: 50px;
  cursor: pointer;
}
.tooltips {
  opacity: 0;
  background: #737aa3;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  position: absolute;
  top: -90px;
  left: 0;
  width: 215px;
  font-size: 18px;
  color: #fff;
  letter-spacing: -0.45px;
  text-align: center;
  padding: 13px;
  line-height: 18px;
}
.tooltips::after {
  position: absolute;
  left: 16px;
  bottom: -18px;
  border-top: 9px solid #737aa3;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid transparent;
  content: "";
}
.open-tooltip .tooltips {
  opacity: 1;
}
.tabbing-sec .nav-item.open-tooltip .o-tooltip {
  display: none;
}
.nav-item.open-tooltip .nav-item {
  z-index: 9999;
}
.flip-tab .tooltips {
  left: -100px;
}
.flip-tab .tooltips::after {
  left: 50%;
}
.msg-tab .tooltips {
  left: auto;
  right: 0;
}
.msg-tab .tooltips::after {
  left: auto;
  right: 15px;
}
.tabbing-sec .nav-link.close-links {
  background: #ff7259;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.incall-mobile .tabbing-sec .nav-item {
  margin-left: 23px;
}
.slider {
  -webkit-appearance: none;
  height: 2px;
  width: 232px;
  background: rgba(115, 122, 163, 0.4);
  position: relative;
  outline: none;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #38417c;
  position: absolute;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  border: 0;
}

.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #38417c;
  position: absolute;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  border: 0;
}
/***  range slider css ***/
input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
  width: 232px;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 232px;
  height: 2px;
  background: rgba(115, 122, 163, 0.4);
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #38417c;
  margin-top: -5px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type="range"]::-moz-range-track {
  width: 232px;
  height: 2px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #38417c;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 232px;
  height: 2px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}
input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}
input[type="range"]::-ms-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #38417c;
}

.selected select {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 19px;
  border-top: 1px solid #d2d4de;
  width: 100%;
  border: 0;
}

#video-area video {
  width: 100%;
}

.participant video {
  border-radius: 8px;
}

.incall-middle {
  overflow: hidden;
  border-radius: 8px;
}

.incall-middle video {
  transform-origin: center;
  transform: scale(162%);
}

.incall-mobile .incall-chat {
  height: 100% !important;
}

.incall-mobile .incall-chat {
  top: 7% !important;
  max-height: 85vh !important;
}

.incall-mobile .chat-text-main {
  height: 75vh;
  overflow: scroll;
  margin-bottom: 5vh;
}

.videos {
  position: relative;
  height: 61vh;
  background-image: url("../images/pleasewaitscreen.png");
  background-size: cover;
}

.videos .participant {
  overflow: hidden;
  height: 67vh;
}

.videos .participant video {
  width: 100%;
  transform-origin: center;
  transform: scale(1.9);
  height: 78%;
}

.right {
  float: right;
  margin-bottom: 30px;
  width: 80%;
}

.left {
  float: left;
  margin-bottom: 30px;
  width: 80%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-select-container {
  z-index: 2 !important;
}

.ReactModal__Overlay {
  z-index: 9 !important;
}

.localVideoDiv video {
  width: 25%;
  bottom: 3%;
  right: 2%;
  position: absolute;
}

.chat {
  height: 770px !important;
  /* height: 100% !important; */
}

.ReactModal__Content {
  width: 50%;
  border-radius: 8px;
}

.error-message {
  color: red;
  font-weight: bold;
}

.not-open {
  background-color: red;
}

#video-preview video {
  width: 100%;
}

.help-box li {
  color: #38417c;
  margin-bottom: 15px;
  margin-top: 20px;
  list-style: circle;
  font-size: 18px;
  margin-left: 20px;
}

.help-box li b {
  font-weight: bolder;
}

/*** office home.inbox page css start ***/
.office-home-right .box-wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.close-chat.submit-chat {
  background: #38417c url(../images/submit.png) no-repeat center center;
}
.chat-window h2 {
  float: left;
  width: calc(100% - 40px);
  color: #38417c;
  margin: 0;
}
.chat-toggles {
  position: relative;
  float: right;
}
.chat-options {
  position: absolute;
  top: 100%;
  right: -3px;
  background: #ffffff;
  border: 1px solid rgba(56, 65, 124, 0.17);
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  width: 185px;
  padding: 7px;
  margin-top: 7px;
}
.chat-options li a {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(56, 65, 124, 0.8);
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.chat-options li {
  border-top: 1px solid rgba(56, 65, 124, 0.17);
}
.chat-options li:first-child {
  border-top: 0;
}
.chat-options li a:hover,
.chat-options li a:focus {
  color: rgba(35, 39, 70, 1);
}
.chat-window {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  /* height: 500px; */
  /* overflow: scroll; */
}
.inbox-right {
  padding: 80px 30px 100px 140px;
  position: relative;
  z-index: 9;
}
.new-msg {
  padding-right: 15px;
}
.chat-window .chat-box {
  background: #38417c;
}
.chat-window .chat-box p {
  color: #fff;
}
.chat-time {
  color: rgba(56, 65, 124, 0.7);
}
.left .chat-box p {
  color: #38417c;
}
.left .chat-box .chat-time {
  color: rgba(56, 65, 124, 0.5);
}
.new-msg-info h3 {
  color: #000;
  font-weight: bold;
}
.new-msg-info p {
  font-size: 15px;
  line-height: 17px;
  margin-top: 10px;
}
.new-msg-info {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
  padding: 20px 23px 20px 20px;
  transition: 0.4s;
  border: 2px solid transparent;
  margin-top: 12px;
  cursor: pointer;
  text-align: left;
}
.new-msg-info:last-child {
  margin-bottom: 12px;
}
.new-msg-info:first-child {
  margin-top: 0;
}
.new-msg-info.new-msg-dots {
  padding-left: 25px;
}
.new-msg-info:hover,
.new-msg-info.active {
  border: 1px solid #38417c;
}
.new-msg-info h3 span {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: rgba(56, 65, 124, 0.5);
  float: right;
}
.new-msg-info-inner {
  position: relative;
}
.new-msg-dots h3 {
  position: relative;
}
.new-msg-dots h3:before {
  height: 7px;
  width: 7px;
  display: inline-block;
  vertical-align: middle;
  background: #38417c;
  border-radius: 100%;
  position: absolute;
  left: -15px;
  top: 4px;
  content: "";
}
.chat-window .chat-text {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.chat-window .type-msg .common-input {
  border: 1px solid #38417c;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  font-size: 18px;
  color: rgba(56, 65, 124, 0.6);
}
.chat-window .type-msg {
  bottom: 20px;
  width: calc(100% - 40px);
  left: 20px;
}
.chat-window .type-msg.with-close form {
  width: calc(100% - 100px);
  float: left;
}
/*** custom scrollbar css start ***/
.custom-scrl-content {
  overflow: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 572px;
}
.custom-scrl-content.hidden {
  display: none;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(56, 65, 124, 0.3) !important;
  border-radius: 5px !important;
  width: 8px !important;
  height: 152px;
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: rgba(56, 65, 124, 0.1) !important;
  border-radius: 0 !important;
  width: 8px !important;
}
.mCSB_scrollTools {
  right: -3px;
  top: 0;
}
/*** custom scrollbar css end ***/
.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
  padding: 0px 18px 0 15px;
  margin-right: 0 !important;
}
.new-msg-btn {
  padding: 0 18px 0 15px;
}
.inbox-msgs {
  margin-top: 12px;
}
.inbox-modal .modal-backdrop.show {
  opacity: 1;
}
.inbox-modal .modal-backdrop {
  background-color: rgba(52, 54, 66, 0.95);
}
.new-message-modal .modal-content {
  padding: 35px 40px;
  border-radius: 8px;
}
.new-message-modal .modal-content .modal-body,
.new-message-modal .modal-content .modal-footer,
.new-message-modal .modal-content .modal-header {
  padding: 0;
}
.new-message-modal .modal-footer .btn {
  width: 100%;
  font-size: 18px;
  height: 56px;
}
.new-message-modal .close {
  background: #38417c url(../images/close-modal.png) no-repeat center center !important;
  opacity: 1 !important;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  padding: 0 !important;
  margin: 0;
  position: absolute;
  right: -10px;
  top: -10px;
}
.new-message-modal .modal-content .modal-header .modal-title {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 25px;
}
.new-message-modal .modal-dialog {
  max-width: 360px;
}
.new-message-modal .modal-content .modal-footer {
  padding-top: 13px;
}
.new-message-modal .modal-body form .form-group label {
  font-size: 13px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.5);
  display: block;
  margin-bottom: 5px;
}
.new-message-modal .modal-body form .form-group {
  background: #ffffff;
  border: 1px solid #d2d4de;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  margin-bottom: 12px;
}
.new-message-modal .modal-body form input[type="text"] {
  width: 100%;
  color: #38417c;
}
.new-message-modal form input::placeholder,
.new-message-modal form textarea::placeholder {
  opacity: 1 !important;
}
.new-message-modal .modal-body form textarea {
  border: 0;
  width: 100%;
  resize: none;
  color: rgba(56, 65, 124, 0.5);
  font-size: 18px;
  min-height: 150px;
  padding-top: 15px;
}
.modal-footer .btn:focus {
  box-shadow: none;
}
.show-mobile {
  display: none !important;
}
.cancel-wrapper.message-window {
  display: none !important;
}

.chat-content {
  height: 390px;

  /* overflow-y: scroll; */
}

.left .chat-box {
  background-color: #fff;
}

.video-preview-area video {
  width: 100%;
}

.video-component {
  position: relative;
}

.remote-participant {
  width: 100%;
  height: 585px;
  background-color: rgba(56, 65, 124, 0.5);
  border-radius: 8px;
}

.local-participant-video {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 20%;
}

.end-call-button {
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 145px;
  height: 56px;
  background-color: #ff7259;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  color: white;
  font-family: "EuclidFlexMedium";
  font-size: 18px;
  line-height: 18px;

  /* identical to box height, or 100% */
  text-align: center;
  letter-spacing: -0.45px;
  font-weight: bold;
  color: #ffffff;
  padding-top: 20px;
  cursor: pointer;
}

.chat-title h3 {
  font-size: 18px;
  line-height: 18px;

  /* identical to box height, or 100% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #38417c;
}

.right .chat-box {
  background-color: #38417c;
  color: white;
}

.right .chat-box p {
  color: white;
}
.right .chat-box .chat-time {
  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.5;
}

.chat-content {
  height: 85%;
}

.chat-content-container {
  height: 85% !important;
  padding-top: 15px;
}

#root
  > div
  > div
  > div
  > section
  > div
  > div
  > div
  > div
  > div.content-wrapper.office-home-right
  > div
  > div:nth-child(1)
  > a
  > div
  > div
  > div.office-home-info
  > p {
  max-width: 160px;
}

.form-control .react-select__control {
  width: 100% !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  border-radius: 8px !important;
  border: 0 !important;
}

.react-select__menu {
  position: relative !important;
  z-index: 99999 !important;
}

.react-datepicker-popper {
  z-index: 30 !important;
}

.socket-indicator {
  background-color: white;
  box-shadow: 0px 2px 14px rgba(56, 65, 124, 0.17);
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 200px;
  height: auto;
  z-index: -1;
  border-radius: 8px;
  cursor: pointer;
}

.socket-indicator-container {
  padding: 10px;
}

.covid-19-button {
  background: #38417c;
  /* Element Dropshadow */

  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
  font-family: "EuclidFlexMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  /* or 92% */

  display: flex;
  align-items: center;
  letter-spacing: -0.25px;

  color: #ffffff;

  mix-blend-mode: normal;
  width: 100%;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.video-settings-bubble {
  width: 300px;
  box-shadow: 0px 2px 14px rgba(56, 65, 124, 0.363309);
  background: #fff;
  border-radius: 8px;
}

.video-settings-bubble-container {
  padding: 25px;
}

.video-settings-bubble-container h3 {
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 23px;
  /* identical to box height, or 96% */

  letter-spacing: -0.6px;
  text-align: left;
  color: #38417c;
}

.video-settings-bubble-container label {
  font-family: "Proxima Nova";
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height, or 131% */

  letter-spacing: -0.325px;

  color: #38417c;

  mix-blend-mode: normal;
  opacity: 0.7;
}

.video-settings-bubble-container .form-group {
  text-align: left;
}

.call-img-main {
  float: left;
}

.appointment-modal-confirmation {
  font-family: "Euclid Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  /* or 106% */

  letter-spacing: -0.9px;

  /* Paragraph */
  text-align: center;
  color: #343642;
  margin-bottom: 20px;
}

.ReactModal__Content p {
  text-align: center;
  margin-bottom: 20px;
}
.app-con-right {
  margin-bottom: 35px;
}

.locationmap iframe {
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 15px;
  max-width: 100%;
}
.leftbar-common .cancel-wrapper {
  margin-bottom: 40px;
}
.leftbar-common h5 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: #38417c;
  margin-bottom: 15px;
}
.leftbar-common h2 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -0.9px;
  color: #343642;
  max-width: 330px;
}
.locationmap {
  margin-bottom: 40px;
}
.main-bg-sectoin .disclaimer h1 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -1.5px;
  color: #343642;
}
.disclaimer p {
  font-family: "ProximaSoft-Regular";
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
  color: #343642;
  margin-bottom: 10px;
}
.disclaimer ul {
  margin-left: 35px;
  list-style: circle;
  font-family: "ProximaSoft-Regular";
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.25px;
  color: #343642;
  list-style-type: disc;
}

.disclaimer li {
  list-style-type: circle;
  margin-bottom: 5px;
}

.disclaimer h3 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 24px;
  color: #343642;
  margin: 25px 0;
}
.accept-disclaimer {
  border-top: 1px solid rgba(52, 54, 66, 0.3);
  margin: 45px 0 0 0;
  padding-top: 20px;
}
.accept-disclaimer label {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: #000000;
}
.accept-disclaimer .checkbox-custom + .checkbox-custom-label::before {
  content: "";
  border: 2px solid #73799f;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.accept-disclaimer .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 20px;
  height: 20px;
}
.middle-content {
  padding-bottom: 60px;
}
.accept-disclaimer .primary-btn {
  max-width: 357px;
  float: right;
  margin-top: 73px;
  background: #38417c;
  height: 65px;
}
.accept-disclaimer .primary-btn:hover,
.accept-disclaimer .primary-btn:focus {
  border: 1px solid #38417c;
  background: transparent;
}
.accept-disclaimer .primary-btn span {
  margin-right: 20px;
}
/*** select tesitng site1 page css start ***/
.leftbar-common p {
  font-size: 19px;
  letter-spacing: -0.25px;
  color: #73799f;
  max-width: 250px;
  line-height: 23px;
}
.search-testing-site > p {
  color: #73799f;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
}
.search-testing-site .s-box {
  max-width: 100%;
}
.select-testing-booking .booking-box > h3 {
  line-height: 30px;
}
.select-testing-booking .booking-info-main {
  padding-bottom: 20px;
}
.select-testing-booking .booking-info-main > h2 {
  margin-bottom: 25px;
}
/*** select tesitng site2 page css start ***/
.serch-result-main .s-box .input-group-text {
  background: transparent;
}
.serch-result-main .s-box .form-control {
  background: transparent;
}
.serch-result-main .s-box {
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  height: 53px;
  line-height: 53px;
  position: relative;
}
.search-testing-site .s-box .form-control {
  font-family: "ProximaSoft-Regular";
  padding: 10px 22px 10px 0;
}
.search-testing-field .search-close {
  background: url(../images/search-close.svg) no-repeat right;
  position: absolute;
  right: 23px;
  top: 20px;
  width: 13px;
  height: 15px;
}
.serch-result-main .search-list ul {
  background: #f8f8fd;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  max-width: 100%;
}
.serch-result-main .search-list {
  margin-top: 30px;
}
.serch-result-main .search-list h4 {
  font-family: "ProximaSoft-Regular";
  line-height: 20px;
}
.search-testing-site .form-control {
  color: rgba(52, 54, 66, 0.8);
}
/*** select tesitng site2 page css end ***/
/*** add dependent page css start ***/
.add-dependent-main .leftbar-common h1 {
  max-width: 355px;
}
.select-dependent > h2 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -0.9px;
  color: #343642;
  margin-bottom: 10px;
}
.select-dependent p {
  font-size: 14px;
  line-height: 16px;
  color: rgba(56, 65, 124, 0.5);
  margin-top: 10px;
}
.list-dependent {
  margin-top: 45px;
}
.list-dependent .visited-list {
  margin-bottom: 10px;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  padding: 14px 50px 14px 20px;
}
.disable-list {
  opacity: 0.4;
}
.list-dependent .visited-list h2 {
  font-family: "EuclidFlex-Medium";
}
.list-dependent .primary-btn {
  max-width: 193px;
  float: right;
  height: 65px;
  margin-top: 48px;
  opacity: 0.4;
}
.list-dependent .primary-btn .fa {
  font-size: 20px;
  margin-left: 20px;
}
.list-selected {
  background: #f8f8fd;
}
.list-completed-result.list-dependent .primary-btn {
  opacity: 1;
}
/*** questionnaire summary css start ***/
.with-opacity {
  opacity: 0.5;
}
.cancel-wrapper a .fas {
  font-size: 19px;
}
.summary-btn li {
  float: left;
  margin-left: 30px;
  width: calc(50% - 15px);
  max-width: 50%;
}
.summary-btn li .primary-btn {
  width: 100%;
  max-width: 100%;
}
.summary-btn li:first-child {
  margin-left: 0;
}
.summary-btn {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-top: -15px;
}
.list-completed-result.list-dependent .primary-btn.opacity-btn {
  opacity: 0.7;
}

.covid-testing .options .calendar-wrapper .soonest-date {
  height: 400px;
  overflow: scroll;
}

.call-img-main {
  position: relative;
  margin-top: 16px;
  border: 2px solid transparent;
  border-radius: 12px;
  overflow: hidden;
}
.call-img-main.active {
  border: 2px solid #38417c;
}

video {
  width: 100%;
}

.accept-button {
  background: #38417c;
  border-color: #38417c;
  border-radius: 8px;
  padding: 11px;
  color: white;
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  /* or 131% */

  text-align: center;
}

.decline-button {
  background: white;
  border-color: #38417c;
  border-radius: 8px;
  padding: 11px;
  color: #38417c;
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  /* or 131% */

  text-align: center;
}
.button-container {
  text-align: center;
}

.modal-title {
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: #38417c;
}

.gradient-overlay {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 62.5%,
    rgba(0, 0, 0, 0.33) 83.85%,
    rgba(0, 0, 0, 0.85) 99.48%
  );
  position: absolute;
  height: 100%;
  width: 100%;
}

.call-mobile .call-img-main {
  background: url(../images/userimage.png);
  background-size: cover;
  max-height: 140px;
}

.password-info {
  font-family: "ProximaSoft-Regular";
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.25px;
  color: #343642;
  list-style: disc;
}

.password-info li {
  list-style: disc;
  margin-left: 10px;
}
label {
  position: relative;
  padding-left: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
}

.labels [type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.labels [type="checkbox"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: -5px;
  top: 2px;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-image: url("../../assets/images/uncheked.svg");
}
.labels [type="checkbox"]:checked + label:before,
[type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: -5px;
  top: 2px;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-image: url("../../assets/images/full-check-icon.svg");
}

.show-ohip {
  height: 100px;
}

.show-ohip label {
  /* width: 30% !important; */
  float: left;
  font-weight: 500;
  font-size: 19px !important;
  /* padding-left: 5px; */
}
.show-ohip label::before {
  margin-right: 10px;
}

.labels {
  width: 80px;
  float: left;
  /* display: inline; */
  height: 100%;
  position: relative;
}

.labels span {
  font-size: 14px;
}

.questionnaire-label {
  padding-left: 0 !important;
  margin-bottom: 3px !important;
}

#student-card {
  margin-bottom: 20px;
}
.validate-email-page .box-wrapper .primary-btn {
  margin-top: 30px;
}

.validate-email-page .box-wrapper {
  height: auto;
  text-align: center;
  margin-top: 30px;
}

.validate-email-page .box-wrapper *:last-child {
  margin-bottom: 15px;
}

.validate-email-page {
  padding-bottom: 50px;
}

.questionnaire-buttons {
  margin-top: 38px;
}
.bluefade {
  background: #73799f;
}

h5.selectedborder {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.25px;
  padding: 6px 11px;
  text-transform: capitalize;
  background: #fff;
}
.mar-10 {
  margin-right: 10px;
}
h5.selectedborder svg {
  width: 16px;
  margin-right: 15px;
}
.mapboxgl-map {
  border-radius: 8px;
}
.options__map_wrap {
  border: 1px solid #d1d4e3;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  margin-bottom: 28px;

  display: inline-block;
}

.appointments-for-list:not(.appointments-diff-style) {
  background: #f8f8fd;
  border: 1px solid #d1d4e3;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  padding: 17px;
  padding-bottom: 20px;
}

.appointments-for-list h4 {
  color: #343642;
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  /* or 129% */
  display: flex;
  align-items: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.appointments-for-list:not(.appointments-diff-style) .visited-list {
  border-radius: 11px;
  border: 1px solid #d1d4e3;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  margin-bottom: 14px;
}

.appointments-for-list h2 {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #343642;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}

.appointments-for-list .profile {
  width: 25px;
  height: 25px;
}

button.n-continuebtn {
  display: none !important;
}
.appointments-diff-style {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  margin-bottom: 23px;
}

.appointments-diff-style > div {
  padding: 14px 12px;
}

.appointments-diff-style > div:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.app-con-right.review-con-right {
  box-shadow: 0px 0px 3px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
}

.review-page {
  background: #fff;
}

.location-type p.small {
  font-size: 18px;
}

.location-type-2 {
  margin: 23px 0 35px;
}
.location-type-2 p {
  font-size: 18px;
}
.cancel-wrapper a {
  cursor: pointer;
}

.location-type-2 p:not(:first-child) {
  margin-top: 6px;
}
.mb-23 {
  margin-bottom: 23px;
}
.font-euculid {
  font-family: Euclid Flex;
}
.primary-btn.whitebtn {
  color: #343642;
  background: #f8f8fd;
  border: 1px solid #d1d4e3;
  box-sizing: border-box;
  border-radius: 12px;
}
.office-icons.icons-small2 {
  width: 42px;
  margin-right: 12px;
}
.covid-testing .options__nav button:last-child {
  border-left: 2px solid #38417c;
}
.covid-testing .options__nav button:last-child {
  border-left: 2px solid #38417c;
}

.covid-testing .options__content {
  margin-top: 49px;
}

.covid-testing .options__clinicInfo .cancel-wrapper {
  margin-bottom: 37px;
  margin-top: 30px;
}
.covid-testing .options + footer .hideLink {
  display: none !important;
}
.covid-testing .options + footer .container > div {
  justify-content: flex-end !important;
}
.appoint-confirm-des {
  margin: 30px 0;
}
.covid-testing .circle-img-wrppaer {
  display: none !important;
}
.new-arrow a {
  font-family: "Proxima Soft" !important;
  font-weight: 500;
  color: #000000 !important;
}

.options,
.options + footer {
  background: #fff;
  border-top: 0 !important;
  overflow: hidden;
}
footer .primary-btn {
  padding-left: 30px;
  padding-right: 22px;
  border-radius: 12px;
  width: auto;
  min-width: 193px;
}
.view-triage {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1.25px;
  color: #73799f !important;
  text-transform: uppercase;
}

.w100 {
  width: 100%;
}
footer .primary-btn svg {
  margin-left: 21px !important;
}
@media (max-width: 767px) {
  .add-calendar-cancel-appt ul li:last-child a {
    margin-top: 15px;
  }
  .questionnaire-buttons .primary-btn {
    max-width: 100%;
  }

  .covid-testing .options__clinicInfo {
    padding: 0 15px;
  }
  .main-bg-sectoin.desktop-page h1 {
    text-align: center;
  }
}

.counter {
  height: 24px;
  width: 24px;
  background: #ff5959;
  font-size: 13px;
  letter-spacing: -0.325px;
  border-radius: 100%;
  color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
}

.new-message-modal .modal-body form .form-group {
  background: #ffffff;
  border: 1px solid #d2d4de;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  margin-bottom: 12px;
}
.new-message-modal .modal-body form textarea {
  border: 0;
  width: 100%;
  resize: none;
  color: #73799f;
  font-size: 19px;
  min-height: 316px;
  padding-top: 15px;
  background: transparent !important;
}
